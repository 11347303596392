import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const DienstleistungenPage = () => (
  <Layout>
    <SEO title="Dienstleistungen" />

    <h1 id="ueberschrift">Informationen zum Serviceangebot</h1>
    <Link to="/fdml_4a_dienstleistungen-klartext#ueberschrift" title="Detaillierte Informationen zum Serviceangebot" style={{fontSize: "1.5em"}}>zum Klartext</Link>

    <p className="poem" style={{marginTop: "0.5em"}}>
      Ein Treffen ist Dein großer Traum?<br />
      Soll’s draußen sein oder im Raum?<br />
      Und weißt Du auch zu welcher Stund‘?<br />
      Gibt’s einen ganz bestimmten Grund?<br />
      Willst Du die Firma präsentieren?<br />
      Oder den Freunden gern pläsieren?<br />
      Ob Messe, Workshop oder Fest<br />
      Die Planung gibt Dir bald den Rest?<br />
      Dann hol Dir Hilfe schnell ins Haus<br />
      Und schmeiße Stress und Hektik raus.
    </p>

    <p className="poem">
      Mit Fachkenntnis und Fantasie<br />
      Wird es ein Treffen so wie nie.
    </p>
  </Layout>
              )

              export default DienstleistungenPage
